(function () {
    'use strict';

    var TOOL_TYPES = ['OTDR'];

    /**
     ToolPresetModal
     */
    angular.module('aerosApp').factory('ToolPresetModal', ToolPresetModal).run(listenEvents);

    listenEvents.$inject = ['ToolPresetModal', 'appDispatcher', 'TestSetupFiberModeModal'];

    function listenEvents(ToolPresetModal, appDispatcher, TestSetupFiberModeModal) {
        appDispatcher.on('addToolPreset', handle('add'));
        appDispatcher.on('editToolPreset', handle('edit'));
        appDispatcher.on('viewToolPreset', handle('VIEW'));

        function handle(mode) {
            return function (e, model) {
                if (model.toolType === "OTDR" && model.mode === "ADD" && !model.name) {
                    TestSetupFiberModeModal.open(model, mode).then(function () {
                        ToolPresetModal.open(model, mode);
                    });

                } else {
                    ~TOOL_TYPES.indexOf(model.toolType) &&
                    ToolPresetModal.open(model, mode);
                }
            }
        }
    }

    ToolPresetModal.$inject = ['$uibModal', 'ToolsDefinitionService', 'PresetService', 'aerosApi', '$state'];

    function ToolPresetModal($uibModal, ToolsDefinitionService, PresetService, aerosApi, $state) {
        return {open: open};

        function open(model, mode) {
            return $uibModal.open({
                size: "lg",
                templateUrl: '/static/templates/tools/ToolPresetModal.html',
                controller: aflToolPresetModalController,
                resolve: {
                    mode: function () {
                        return mode;
                    },
                    model: function () {
                        return angular.copy(model);
                    },
                    definition: () => ToolsDefinitionService.fetch()
                            .then(PresetService().updateDefinition),
                    tools: function () {
                        return aerosApi.getOrgToolsByType({
                            orgId: $state.params.id,
                            toolType: model.toolType.toLowerCase()
                        })
                    }
                }
            }).result;

        }
    }

    aflToolPresetModalController.$inject = ['$scope', '$uibModalInstance', 'definition', 'model', 'mode', '$state', 'PresetService',
        'appDispatcher', 'aerosApi', 'tools', 'ToolsDefinitionService', 'UnitsMeasurementService', 'UserProfileService',];

    function aflToolPresetModalController($scope, $uibModalInstance, definition, model, mode, $state, PresetService, appDispatcher, aerosApi,
                                          tools, ToolsDefinitionService, UnitsMeasurementService, UserProfileService) {


        $scope.presetModel = _.defaults(model, {
            fields: {type: $state.params.presetType}
        });

        $scope.presetModel.fields = addPresetType($scope.presetModel);
        $scope.presetModel.fields.type = $state.params.presetType;
        $scope.presetModel.fields.toolType = model.toolType;

        $scope.properties = [];

        angular.extend($scope, {
            isVisible: isVisible
        });

        setUnitFields();

        function setUnitFields() {
            var units = {
                Meters: {
                    name: "meters"
                },
                Kilometers: {
                    name: "meters"
                },
                Miles: {
                    name: "miles",
                    toMeters: UnitsMeasurementService.milesToMeters,
                    fromMeters: UnitsMeasurementService.metersToMiles
                },
                Kilofeet: {
                    name: "feet",
                    toMeters: UnitsMeasurementService.feetToMeters,
                    fromMeters: UnitsMeasurementService.metersToFeet
                },
                Feet: {
                    name: "feet",
                    toMeters: UnitsMeasurementService.feetToMeters,
                    fromMeters: UnitsMeasurementService.metersToFeet
                }

            };

            var userProfile = UserProfileService.get();

            $scope.presetModel.fields.units = units[userProfile.user.preferredUnitOfMeasure].name;
            $scope.presetModel.fields['lossDistanceUnit'] = userProfile.user.preferredLossDistanceUnit;
            $scope.units = units[userProfile.user.preferredUnitOfMeasure];
        }

        if ($scope.presetModel.fiberMode) {
            $scope.presetModel.fields.fiberMode = $scope.presetModel.fiberMode;
        }

        if ($scope.presetModel.name) {
            $scope.presetModel.fields.name = $scope.presetModel.name;
        }

        $scope.mode = mode;
        $scope.group = getGroup(definition, model);

        addDefinition($scope.properties, getWavelengthDefinition(definition));
        addDefinition($scope.properties, getFiberModeDefinition(definition));
        addDefinition($scope.properties, getNameDefinition(definition));

        $scope.generic = getPresets(tools.data.tools);
        // Don't propogate the generic name into the form
        $scope.generic.Singlemode.name = '';
        $scope.generic.Multimode.name = '';

        $scope.savePreset = function () {
            if (mode === 'add') {
                createPreset();
            } else if (mode === 'edit') {
                updatePreset();
            }
        };

        function getPresets(tools) {
            var generic = {};

            angular.forEach(tools, function (tool) {
                if (tool.generic) {
                    generic[tool.fiberMode] = ToolsDefinitionService.format.nested2flat(tool)
                }
            });

            return generic;
        }

        function getGroup(definition, model) {
            return definition[model.toolType].groups.find(function (g) {
                return g.id === $state.params.presetType;
            });
        }

        function addDefinition(fields, definition) {
            fields.unshift(definition);
        }

        function getPropertyDefinition(definition, name) {
            return definition[model.toolType].properties.find(function (element) {
                return element.fieldName === name;
            });
        }

        function getNameDefinition(definition) {
            return getPropertyDefinition(definition, 'name');
        }

        function getFiberModeDefinition(definition) {
            return getPropertyDefinition(definition, 'fiberMode');
        }

        function getWavelengthDefinition(definition) {
            var wavelengthsDefinition = getPropertyDefinition(definition, 'wavelengths');

            wavelengthsDefinition.fieldDescription.visible = false;
            return wavelengthsDefinition;
        }

        function removeGroupIdIfNeed(value) {
            return value.split('.').splice(1).join('.');
        }

        function updatePreset() {
            // aeRos 3586  - we must create a copy of the presetModel first
            // before calling removePresetType, because it clears all preset fields on the form
            // If is done not on a copy, but on the original preset,
            // and if REQUIRED preset field is empty (cleared by removePresetType), this
            // generates an error message, because REQUIRED field cannot be empty

            var modelToSave = angular.copy($scope.presetModel);
            modelToSave.fields = removePresetType(modelToSave.fields);
            modelToSave.name = model.fields.name;

            modelToSave.update(modelToSave).then($uibModalInstance.close)
                .then(
                    appDispatcher.emit('toolPresetUpdateSuccess'),
                    appDispatcher.emit('toolPresetCreateError'))

        }

        function isVisible(field, model) {
            return field.fieldDescription.parent ? visible(field, model) : true
        }

        function visible(field, model) {
            var parentName = field.parent || field.fieldDescription.parent,
                description = field.fieldDescription || field;

            if (description.parent_value && model.fields[parentName] && description.values[model.fields[parentName][description.parent_value]]
                && description.values[model.fields[parentName][description.parent_value]].parent) {
                return visible(description.values[model.fields[parentName][description.parent_value]], model)
            } else {
                return (description.values[model.fields[parentName]]) || (description.parent_value && model.fields[parentName] && description.values[model.fields[parentName][description.parent_value]])
            }

        }

        function addPresetType(preset) {
            angular.forEach(Object.keys(preset.fields), function (field) {
                var type = preset.type || preset.group;
                if (type) {
                    preset.fields[type + '.' + field] = preset.fields[field];
                    if (field !== "fiberMode") {
                        delete preset.fields[field]
                    }
                }
            });
            return preset.fields;
        }

        function removePresetType(preset) {
            angular.forEach(Object.keys(preset), function (field) {
                var splitArr = field.split('.'),
                    fieldName;
                if (splitArr[0] === preset.type) {
                    fieldName = splitArr.splice(1).join('.');
                    preset[fieldName] = preset[field];
                    delete preset[field];
                }
            });
            return preset;
        }

        function createPreset() {
            var modelToSave = angular.copy($scope.presetModel);
            modelToSave.fields = removePresetType(modelToSave.fields);
            PresetService(model.toolType).create($state.params.id, modelToSave, $state.params.presetType).then(
                appDispatcher.emit('toolPresetUpdateSuccess'),
                appDispatcher.emit('toolPresetUpdateError')
            ).then($uibModalInstance.close)
        }

    }

}());
